import './loading.scss';

class LoadingAnimation extends HTMLElement {
  constructor() {
    super();

    this.body = document.querySelector('body');
    this.mainContent = document.querySelector('.content-for-layout');
  }

  show() {
    if (this.classList.contains('hidden')) {
      this.classList.remove('hidden');
      this.body.classList.add('overflow-hidden');
      this.mainContent.classList.add('overflow-hidden');
    }
  }

  hide() {
    if (!this.classList.contains('hidden')) {
      this.classList.add('hidden');
      this.body.classList.remove('overflow-hidden');
      this.mainContent.classList.remove('overflow-hidden');
    }

    this.setLoadingMessage('');
    this.setSubtitleText('');
  }

  setLoadingMessage(content) {
    this.querySelector('[data-loading-message]').textContent = content;
  }

  setSubtitleText(content) {
    this.querySelector('[data-subtitle-text]').textContent = content;
  }
}

customElements.get('loading-animation') ||
  customElements.define('loading-animation', LoadingAnimation);
